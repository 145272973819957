@import '../../assets/scss/dimensions';
@import '../../assets/scss/colors';
@import '../../assets/scss/numbers';
@import '../../assets/scss/vairables';

// font family variables
$roboto: Roboto, sans-serif;
$roboto-bold: RobotoBold, sans-serif;
$roboto-light: RobotoLight, sans-serif;
$roboto-thin: RobotoThin, sans-serif;

.po-overlay {
  display: none;
}

@media only screen and (min-height: $screen-height) {
  .po-overlay {
    display: block;

    &.hidden {
      display: none;
    }
  }

  .po-dimmer {
    background-color: $dimmer-background-color;
    z-index: $index + 100;
    position: absolute;
    top: 0;
    left: 0;
    width: $screen-width;
    height: $screen-height;
    overflow: hidden;
    box-sizing: border-box;

    &.hidden {
      display: none;
    }
  }

  .po-dialog {
    z-index: $index + 101;
    width: 100%;
    position: fixed;
    bottom: #{$dialog-bottom}px;
    display: block;
  }

  .po-normal {
    width: $dialog-width;
    height: auto;
    padding: 39px 54px 54px;
    background-image: $dialog-background-image;
    box-sizing: border-box;
    border-radius: #{$dialog-border-radius}px;
    -webkit-moz-backdrop-filter: blur(#{$dialog-blur}px);
    -webkit-op-backdrop-filter: blur(#{$dialog-blur}px);
    backdrop-filter: blur(#{$dialog-blur}px);
    box-shadow: $dialog-box-shadow;
    margin: 0 auto;
  }

  .show-dialog {
    animation: show-dialog #{$animation-duration}s;
  }

  .hide-dialog {
    animation: hide-dialog #{$animation-duration}s;
    transform: translateY(180%);
  }

  .po-header {
    width: auto;
    display: block;

    .po-title {
      width: auto;
      height: $title-height;
      margin: $dialog-title-margin;
      font-family: $roboto-bold;
      font-size: #{$title-font-size}px;
      font-stretch: normal;
      font-style: normal;
      line-height: $title-line-height;
      letter-spacing: normal;
      color: $text-color;
    }
  }

  .po-divider {
    width: auto;
    height: $divider-height;
    margin: 27px 0 24px;
    opacity: $divider-opacity;
    background-color: $divider-background-color;
  }

  .po-overlay-description {
    width: $description-width;
    height: $description-height;
    margin: 12px 0 36px;
    opacity: $text-opacity;
    font-family: $roboto-light;
    font-size: #{$text-font-size}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: $text-line-height;
    letter-spacing: normal;
    color: $text-color;
  }

  .po-input-container {
    width: 100%;
    height: auto;
    margin-top: 36px;
  }

  .inner-boxes {
    display: table;
    margin: 0 auto;
  }

  .po-rectangle {
    width: 108px;
    height: 108px;
    padding: 42px;
    border-radius: 18px;
    background-color: rgba(#bcc0ca, 0.12);
    box-sizing: border-box;
    display: inline-block;

    &.space {
      margin-right: 54px;
    }

    &.highlight {
      border: solid 9px #00a7e1;
    }

    .dot {
      width: 24px;
      height: 24px;
      background-color: $text-color;
      border-radius: 180px;
      display: block;

      &.active {
        margin-left: -9px;
        margin-top: -9px;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

// Animations
@keyframes show-dialog {
  from {
    transform: translateY(180%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes hide-dialog {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(180%);
  }
}
