// stylelint-disable property-no-vendor-prefix
// stylelint-disable value-no-vendor-prefix

@import '../../../assets/scss/colors';
@import '../../../assets/scss/dimensions';
@import '../../../assets/scss/numbers';

.info_standardbg {
  width: $screen-width;
  height: $screen-height;
  object-fit: contain;
  position: absolute;
  top: $info-standardbg-top;
  background: url('./assets/full-bg.png') no-repeat;
}

.full-info-banner {
  width: $screen-width;
  height: $screen-height;
  z-index: 999;
  position: absolute;
}

.full-info-right-container {
  .info-brandingicon {
    right: $info-brandingicon-right;
    top: $info-brandingicon-top;
    width: $info-brandingicon-width;
    height: $info-brandingicon-height;
    margin: $full-info-margin-zero $full-info-margin-zero $full-info-branding-icon-margin;
    object-fit: contain;
    position: absolute;
    content: url('../StandardInfo/assets/tivo.png');
  }

  .info-clock {
    width: auto;
    height: $info-clock-height;
    opacity: $text-opacity;
    font-family: Roboto, sans-serif;
    font-size: #{$text-font-size}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $text-color;
    top: $info-clock-top;
    right: $info-clock-right;
    position: absolute;
  }
}

.full-info-left-container {
  margin-left: $info-title-margin-left;

  .full-info-metadata {
    left: $full-info-metadata-left;
    top: $full-info-metadata-top;
    height: $full-info-metadata-height;
    display: flex;
    align-items: center;

    .genre {
      color: $text-color !important;
      opacity: $text-opacity;
      height: $full-info-metadata-height;
      font-family: Roboto, sans-serif;
      font-size: #{$full-info-metadata-font-size}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: $info-metadata-line-height;
      letter-spacing: normal;
      display: inline-block;
    }

    .meta-data-seperator {
      color: $text-color;
      margin-top: $full-info-metadata-seperator-margin-top;
      display: inline-block;
      opacity: $progress-bar-opacity;
      width: $full-info-metadata-seperator-width;
      height: $full-info-metadata-height;
      margin-left: $full-info-metadata-margin-left-right;
      margin-right: $full-info-metadata-margin-left-right;
      font-family: Roboto, sans-serif;
      font-size: #{$full-info-metadata-font-size}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: $info-metadata-line-height;
      letter-spacing: normal;
    }

    .rating {
      height: $full-info-rating-height;
      width: $full-info-rating-width;
      color: $text-color !important;
      opacity: $text-opacity;
      left: $full-info-metadata-rating-left;
      display: inline-block;

      &.pg {
        content: url('./assets/pg.png');
      }

      &.g {
        content: url('./assets/g.png');
      }

      &.pg-13 {
        content: url('./assets/pg-13.png');
      }

      &.r {
        content: url('./assets/r.png');
      }

      &.nc-17 {
        content: url('./assets/nc-17.png');
      }

      &.nr {
        content: url('./assets/nr.png');
      }

      &.tv-14 {
        content: url('./assets/tv-14.png');
      }

      &.tv-g {
        content: url('./assets/tv-g.png');
      }

      &.tv-ma {
        content: url('./assets/tv-ma.png');
      }

      &.tv-pg {
        content: url('./assets/tv-pg.png');
      }

      &.tv-y {
        content: url('./assets/tv-y.png');
      }

      &.tv-y7 {
        content: url('./assets/tv-y7.png');
      }
    }

    .duration,
    .first-aired {
      color: $text-color !important;
      opacity: $text-opacity;
      left: $full-info-metadata-margin-left-right;
      font-family: Roboto, sans-serif;
      font-size: #{$full-info-metadata-font-size}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: $info-metadata-line-height;
      letter-spacing: normal;
      display: inline-block;
      height: $full-info-metadata-height;
    }

    .critic-rating-green {
      color: $info-tmdb-green;
    }

    .critic-rating-yellow {
      color: $info-tmdb-yellow;
    }

    .critic-rating-red {
      color: $info-tmdb-red;
    }

    .critic-rating {
      width: $full-info-critic-rating-width;
      height: $full-info-metadata-height;
      font-family: Roboto, sans-serif;
      font-size: #{$full-info-metadata-font-size}px;
      font-weight: $info-tmdb-font-weight;
      font-stretch: normal;
      font-style: normal;
      line-height: $info-metadata-line-height;
      letter-spacing: normal;
    }

    .percentage {
      width: $full-info-critic-rating-percentage-width;
      height: $full-info-critic-rating-percentage-height;
      margin: $full-info-percentage-top $full-info-percentage-right $full-info-percentage-bottom $full-info-percentage-left;
      font-family: Roboto, sans-serif;
      font-size: #{$full-info-critic-rating-percentage-font-size}px;
      font-weight: $info-tmdb-font-weight;
      font-stretch: normal;
      font-style: normal;
      line-height: $info-tmdb-percentage-line-height;
      letter-spacing: normal;
    }

    .tmdb {
      width: $full-info-tmdb-width;
      height: $full-info-tmdb-height;
      margin: $full-info-tmdb-top $full-info-tmdb-right $full-info-tmdb-bottom $full-info-tmdb-left;
      object-fit: contain;
    }

    .release-year {
      width: $full-info-release-year-width;
      height: $full-info-metadata-height;
      margin: $full-info-release-year-top-bottom $full-info-release-year-right-left $full-info-release-year-top-bottom $full-info-release-year-right-left;
      font-family: Roboto, sans-serif;
      font-size: #{$full-info-metadata-font-size}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: $info-metadata-line-height;
      letter-spacing: normal;
      color: $info-subtitle-color;
    }
  }

  .description {
    position: relative;
    top: $full-info-description-top;
    width: $full-info-description-width;
    font-family: Roboto, sans-serif;
    font-size: #{$text-font-size}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: $info-upnext-line-height;
    letter-spacing: normal;
    color: $text-color !important;
    opacity: $text-opacity;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
    text-overflow: clip;
    overflow: hidden;
    word-wrap: break-word;
  }

  .channel-icon {
    position: relative;
    top: $full-info-channel-icon-top;
    width: $full-info-channel-icon-width;
    height: $full-info-channel-icon-height;
    margin-bottom: $full-info-channel-icon-margin-bottom;
  }

  .up-next {
    position: relative;
    margin-top: $full-info-channel-icon-margin-bottom;
    font-family: Roboto, sans-serif;
    font-size: #{$text-font-size}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: $info-upnext-line-height;
    letter-spacing: normal;
    color: $text-color !important;
    opacity: $text-opacity;

    >.up-next-text {
      color: $text-color;
      opacity: $progress-bar-opacity;
    }
  }
}
