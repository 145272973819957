// importing variables
@import 'colors';
@import 'dimensions';
@import 'numbers';

// Dialogs
$dialog-box-shadow: $zero 2px 50px $zero $shadow-start-color, $zero 2px 4px $zero $shadow-end-color;
$dialog-background-image: radial-gradient(circle at 50% 0, #484f5c, #202228 67%);
$dialog-highlight-border: solid 6px $highlight-color;
$dialog-title-margin: 0 0 27px;
