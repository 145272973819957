@import '../../../assets/scss/dimensions';

.trick-play-bar {
  height: $screen-height;
  width: $screen-width;
  position: absolute;
  z-index: 999;
  
  .tpb-bottom-background {
    content: url('./assets/trickplay-bg.png');
    position: absolute;
    width: $screen-width;
    height: $half-screen-height;
    bottom: 0%;
  }
}
