// stylelint-disable property-no-vendor-prefix
// stylelint-disable value-no-vendor-prefix
// stylelint-disable color-named

@import '../../../assets/scss/colors';
@import '../../../assets/scss/dimensions';
@import '../../../assets/scss/numbers';

.info-title-container {
  width: $info-title-width;
  position: relative;
  max-height: $info-title-max-height;

  .info-title,
  .partial-truncation {
    min-height: $info-title-min-height;
    opacity: $text-opacity;
    font-size: #{$info-title-font-size}px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    line-height: $info-title-line-height;
    letter-spacing: normal;
    color: $text-color;
    margin-top: $info-title-margin-top;
    font-stretch: normal;
    text-overflow: clip;
    word-wrap: break-word;
  }

  .mask-title {
    -webkit-mask-image: linear-gradient(to right, black 100%, transparent 100%);
    mask-image: linear-gradient(to right, black 100%, transparent 100%);
    -webkit-mask-position: 25% 0%;
    mask-position: 25% 0%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 56px;
    mask-size: 100% 56px;
  }

  .partial-truncation {
    max-height: $shrink-info-title-max-height;
    overflow: hidden;
    position: relative;
    font-size: #{$shrink-info-title-font-size}px;
    margin-top: #{$top}px;
    top: -($shrink-info-title-max-height);
    -webkit-mask-image: linear-gradient(to right, black 83%, transparent 100%);
    mask-image: linear-gradient(to right, black 83%, transparent 100%);
    -webkit-mask-position: 100% 100%;
    mask-position: 100% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 50px;
    mask-size: 100% 50px;
  }
}
