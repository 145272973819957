@font-face {
  font-family: Roboto;
  src: url('../fonts/roboto_regular.ttf');
}

@font-face {
  font-family: RobotoLight;
  src: url('../fonts/roboto_light.ttf');
}

@font-face {
  font-family: RobotoBold;
  src: url('../fonts/roboto_bold.ttf');
}

@font-face {
  font-family: RobotoThin;
  src: url('../fonts/roboto_thin.ttf');
}
