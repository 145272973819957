@import '../../../assets/scss/dimensions';
@import '../../../assets/scss/numbers';
@import '../../../assets/scss/colors';

.actions-strip-container {
  height: $action-strip-container-height;
  width: $screen-width;
  bottom: $closed-captions-bottom;
  right: $action-strip-right;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: flex-end;

  .actions-strip {
    display: inline-flex;
    height: $action-strip-height;
    width: $screen-width;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}
