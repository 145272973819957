@import '../../../../assets/scss/dimensions';
@import '../../../../assets/scss/numbers';
@import '../../../../assets/scss/colors';

.action-item {
  display: flex;
  align-items: center;
  width: fit-content;
  color: $text-color;
  padding: $closed-captions-padding;
  white-space: nowrap;
  border: #{$closed-captions-border}px solid $text-color;
  border-radius: #{$closed-captions-border-radius}px;
  margin-right: $action-item-right;
  font-family: Roboto, sans-serif;
  font-size: #{$span-font-size}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $span-line-height;
  letter-spacing: normal;
}

.highlighted-action-item {
  border-color: $highlight-color;

  span {
    color: $highlight-color;
  }

  .check-mark {
    content: url('./../../TrickPlayBar/PlaybackControls/assets/player_check_hl.png');
  }
}

.check-mark {
  height: $closed-captions-check-mark-dimension;
  width: $closed-captions-check-mark-dimension;
  margin-right: $closed-captions-check-mark-margin-right;
  content: url('./../../TrickPlayBar/PlaybackControls/assets/player_check.png');
}
