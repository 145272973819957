// importing variables
@import '../../assets/scss/colors';
@import '../../assets/scss/dimensions';
@import '../../assets/scss/numbers';
@import '../../assets/scss/vairables';

.selection-dialog {
  z-index: $dialog-index;
  width: $selection-dialog-width;
  margin: $zero 63px 159px 1089px;
  padding: 39px 54px 54px;
  box-shadow: $dialog-box-shadow;
  background-image: $dialog-background-image;
  position: absolute;
  right: $zero;
  bottom: $zero;
  border-radius: #{$dialog-border-radius}px;
  box-sizing: border-box;
  opacity: $text-opacity;

  .dialog-title {
    width: $selection-dialog-content-width;
    height: $title-height;
    margin: $dialog-title-margin;
    font-family: Roboto;
    font-size: $selection-dialog-title-font-size;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: $title-line-height;
    letter-spacing: normal;
    color: $text-color;
  }

  .options-body-container {
    max-height: $selection-dialog-content-height;
    overflow: scroll;

    .options-list {
      width: $selection-dialog-content-width;
      background-color: $button-background-color;
      border-radius: $selection-dialog-options-border-radius;
      box-sizing: border-box;
      height: $button-height;
      margin: 18px $zero;
      padding: 6px 6px 6px 27px;
    }

    .list-values {
      width: $selection-dialog-options-width;
      height: button-text-height;
      opacity: $text-opacity;
      font-family: Roboto;
      font-size: #{$text-font-size}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      color: $text-color;
      margin: 8px 249px 9px $zero;
    }


    .highlight {
      border: $dialog-highlight-border;
    }

    .highlight-value {
      font-weight: bold;
    }

    .select-mark-button-icon {
      width: 25.5px;
      height: 21.6px;
      margin-right: 16px;
    }
  }

  .dialog-divider {
    width: $selection-dialog-content-width;
    height: $divider-height;
    margin: 27px $zero 36px;
    opacity: $divider-opacity;
    background-color: $divider-background-color;
  }
}
