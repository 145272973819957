@import '../../../../assets/scss/colors';
@import '../../../../assets/scss/dimensions';
@import '../../../../assets/scss/numbers';

.tpb-current-time-cointainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  bottom: $current-time-container-bottom;
  left: $current-time-container-left;
  z-index: 2;
  width: $current-time-container-width;

  .tpb-current-time {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    span {
      opacity: $text-opacity;
      font-family: Roboto, sans-serif;
      font-size: #{$text-font-size}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: $text-line-height;
      letter-spacing: normal;
      color: $text-color;
    }
  
    .tpb-current-time-play-button {
      margin-left: $current-time-play-button-margin-left;
      height: $current-time-play-button-height;
      width: $current-time-play-button-width;
      border-top: #{$current-time-play-button-border-top-bottom}px solid transparent;
      border-bottom: #{$current-time-play-button-border-top-bottom}px solid transparent;
      border-left: #{$current-time-play-button-border-left}px solid $text-color;
    }
  }

  .tpb-current-position {
    height: $current-position-indicator-height;
    width: $current-position-indicator-width;
    background-color: $text-color;
    border-radius: #{$current-position-border-radius}px #{$current-position-border-radius}px 0 0;
  }
}

.tpb-start-time {
  height: $progress-bar-start-time-height;
  right: $progress-bar-start-time-right;
  position: absolute;
  opacity: $text-opacity;
  font-family: Roboto, sans-serif;
  font-size: #{$text-font-size}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $text-line-height;
  letter-spacing: normal;
  color: $text-color;
  bottom: $progress-bar-start-time-bottom;
}

.tpb-progress-bar-with-progress-marks {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $screen-width;
  bottom: $progress-bar-with-progress-marks-bottom;

  .tpb-progress-bar {
    background-color: $progress-bar-color;
    border-radius: #{$progress-bar-border-radius}px;
    height: $progress-bar-height;
    position: absolute;
    width: $progress-nar-width;
    opacity: $progress-bar-opacity;
    left: $progress-bar-left;
    bottom: $progress-bar-bottom;
  }

  .tpb-progress-marks {
    position: absolute;
    left: $progress-marks-left;
    width: $progress-marks-width;
    display: flex;
    flex-direction: row;
    bottom: $progress-marks-bottom;

    .progress-indicator-mark {
      background-color: $text-color;
      border-radius: #{$progress-indicator-border-radius}px;
      height: $progress-indicator-height;
      width: $progress-indicator-width;
      position: absolute;
    }
  }
}

.tpb-end-time {
  width: $progress-bar-end-time-width;
  height: $progress-bar-end-time-height;
  position: absolute;
  right: $progress-bar-end-time-right;
  opacity: $text-opacity;
  font-family: Roboto, sans-serif;
  font-size: #{$text-font-size}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $text-line-height;
  letter-spacing: normal;
  color: #ebebeb;
  bottom: $progress-bar-end-time-bottom;
}
