// global variables
$dimmer-background-color: #80121212;
$shadow-start-color: rgba(0, 0, 0, 0.35);
$shadow-end-color: rgba(0, 0, 0, 0.5);
$highlight-color: #00a7e1;
$button-background-color: rgba(235, 235, 235, 0.08);
$text-color: #ebebeb;
$divider-background-color: #d8d8d8;
$color-secondary: #707070;
$progress-bar-color: #696969;
$info-subtitle-color: rgba(235, 235, 235, 0.9);
$info-tmdb-green: #22d07a;
$info-tmdb-yellow: #d2d531;
$info-tmdb-red: #db2360;
