// importing variables
@import '../../assets/scss/colors';
@import '../../assets/scss/dimensions';
@import '../../assets/scss/numbers';
@import '../../assets/scss/vairables';

// font variables
$roboto: Roboto, sans-serif;
$roboto-bold: RobotoBold, sans-serif;
$roboto-light: RobotoLight, sans-serif;
$roboto-thin: RobotoThin, sans-serif;

.message-overlay {
  display: none;
}

// media query for screen height 1080px
@media only screen and (min-height: $screen-height) {
  .message-overlay {
    display: block;

    &.hidden {
      display: none;
    }
  }

  .dimmer {
    z-index: $index;
    width: $screen-width;
    height: $screen-height;
    position: absolute;
    top: $top;
    left: $left;
    background-color: $dimmer-background-color;
    overflow: hidden;
    box-sizing: border-box;

    &.hidden {
      display: none;
    }
  }

  .dialog {
    z-index: $dialog-index;
    width: 100%;
    position: fixed;
    bottom: #{$dialog-bottom}px;
    display: block;
  }

  .normal-dialog {
    width: $dialog-width;
    height: auto;
    padding: 39px 54px 54px;
    background-image: $dialog-background-image;
    box-sizing: border-box;
    border-radius: #{$dialog-border-radius}px;
    -webkit-moz-backdrop-filter: blur(#{$dialog-blur}px);
    -webkit-op-backdrop-filter: blur(#{$dialog-blur}px);
    backdrop-filter: blur(#{$dialog-blur}px);
    box-shadow: $dialog-box-shadow;
    margin: 0 auto;
  }

  .show-dialog {
    animation: show-dialog #{$animation-duration}s;
    transition: all #{$tansition-duration}ms linear;
  }

  .hide-dialog {
    transform: translateY(180%);
    animation: hide-dialog #{$animation-duration}s;
    transition: all #{$tansition-duration}ms linear;
  }

  .header {
    display: block;
    width: auto;
   
    .title {
      width: $title-width;
      height: $title-height;
      margin: 0 44px 27px 0;
      font-family: $roboto-bold;
      font-size: #{$title-font-size}px;
      font-stretch: normal;
      font-style: normal;
      line-height: $title-line-height;
      letter-spacing: normal;
      color: $text-color;
    }

    .sub-text {
      width: $sub-text-width;
      height: $sub-text-height;
      margin: 4px 0 23px 44px;
      font-family: $roboto-thin;
      font-size: #{$text-font-size}px;
      font-stretch: normal;
      font-style: normal;
      line-height: $sub-text-line-height;
      letter-spacing: normal;
      text-align: right;
      color: $text-color;
      float: right;
    }
  }

  .divider {
    width: $divider-width;
    height: $divider-height;
    margin: 23px 0 21px;
    opacity: $divider-opacity;
    background-color: $divider-background-color;
    display: block;
  }

  .overlay-description {
    width: $description-width;
    height: $description-height;
    margin: 21px 0 36px;
    opacity: $text-opacity;
    font-family: $roboto-light;
    font-size: #{$text-font-size}px;
    font-stretch: normal;
    font-style: normal;
    line-height: $text-line-height;
    letter-spacing: normal;
    color: $text-color;
    display: block;
  }

  .highlight {
    display: block;

    .rectangle {
      width: $button-width;
      height: $button-height;
      padding: 8px 30px 9px 21px;
      border-radius: #{$button-border-radius}px;
      border: $dialog-highlight-border;
      box-sizing: border-box;
      background-color: $button-background-color;
    }

    .text {
      width: $button-text-width;
      height: $button-text-height;
      opacity: $text-opacity;
      font-family: $roboto-bold;
      font-size: #{$text-font-size}px;
      font-stretch: normal;
      font-style: normal;
      line-height: $text-line-height;
      letter-spacing: normal;
      color: $text-color;
    }
  }
}

// Animations
@keyframes show-dialog {
  from {
    transform: translateY(180%);
  }
  
  to {
    transform: translateY(0);
  }
}

@keyframes hide-dialog {
  from {
    transform: translateY(0);
  }
  
  to {
    transform: translateY(180%);
  }
}
