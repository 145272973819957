// global variable declarations
// index & position
$index: 1100;
$dialog-index: $index + 1;
$top: 0;
$left: 0;
$dialog-bottom: 75;
$zero: 0;
// border radius
$dialog-border-radius: 21;
$button-border-radius: 14;
// opacity & blur
$text-opacity: 0.9;
$divider-opacity: 0.2;
$dialog-blur: 40;
$progress-bar-opacity: 0.5;
// font size
$title-font-size: 42;
$text-font-size: 32;
$span-font-size: 30;
$info-title-font-size: 54;
$shrink-info-title-font-size: 49;
$full-info-metadata-font-size: 28;
$full-info-critic-rating-percentage-font-size: 21;
// text line height
$title-line-height: 1.14;
$text-line-height: 1.44;
$sub-text-line-height: 1.5;
$span-line-height: 1.3;
// animations
$animation-duration: 2;
$tansition-duration: 250;

// playback controls
$closed-captions-border: 3;
$closed-captions-border-radius: 6;
// progress bar
$progress-bar-border-radius: 4.5;
$current-time-play-button-border-top-bottom: 10.5;
$current-time-play-button-border-left: 18;
$current-position-border-radius: 3;
$progress-indicator-border-radius: 1.5;

//line height
$info-subtitle-line-height: 1.41;
$info-title-line-height: 1.11;
$info-metadata-line-height: 1.61;
$info-upnext-line-height: 1.41;
$info-tmdb-percentage-line-height: 1.71;

//font weight
$info-tmdb-font-weight: 500;
