@import '../../../../assets/scss/colors';
@import '../../../../assets/scss/dimensions';
@import '../../../../assets/scss/numbers';

.play-back-controls {
  width: $playback-controls-text-width;
  height: $playback-controls-text-height;
  font-family: Roboto, sans-serif;
  font-size: #{$text-font-size}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $text-line-height;
  letter-spacing: normal;
  text-align: center;
  color: $color-secondary;
  position: absolute;
  bottom: $playback-controls-text-bottom;
  left: $playback-controls-text-left;
}

