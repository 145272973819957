// stylelint-disable property-no-vendor-prefix
// stylelint-disable value-no-vendor-prefix

@import '../../../assets/scss/colors';
@import '../../../assets/scss/dimensions';
@import '../../../assets/scss/numbers';

.standard-info {
  position: absolute;
  z-index: 999;
  height: $screen-height;
  width: $screen-width;

  .info_standardbg {
    width: $info-standardbg-width;
    height: $info-standardbg-height;
    object-fit: contain;
    position: absolute;
    top: $info-standardbg-top;
    background: url('./assets/standard-bg.png') no-repeat;
  }

  .info_left-container {
    position: absolute;
    margin-left: $info-title-margin-left;

    .info_subtitle {
      width: $info-subtitle-width;
      height: $info-subtitle-height;
      font-family: Roboto, sans-serif;
      font-size: #{$text-font-size}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: $info-subtitle-line-height;
      letter-spacing: normal;
      color: $info-subtitle-color;
      margin-top: $info-subtitle-margin-top;
    }

    .info_channelLogoBelowSubtitle {
      width: $info-channel-logo-below-subtitle-width;
      top: $info-channel-logo-below-subtitle-top;
      fill: none;
      margin-top: $info-channel-logo-below-subtitle-margin-top;
    }

    .info_channelLogoBelowTitle {
      width: $info-channel-logo-below-title-width;
      top: $info-channel-logo-below-title-top;
      fill: none;
      margin-top: $info-channel-logo-below-title-margin-top;
    }
  }
  
  .info_right-container {
    position: absolute;
    right: 0;

    .info_brandingicon {
      right: $info-brandingicon-right;
      top: $info-brandingicon-top;
      width: $info-brandingicon-width;
      height: $info-brandingicon-height;
      margin: 0 0 11px;
      object-fit: contain;
      position: absolute;
      content: url('./assets/tivo.png');
    }

    .info_clock {
      width: max-content;
      height: $info-clock-height;
      opacity: $text-opacity;
      font-family: Roboto, sans-serif;
      font-size: #{$text-font-size}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $text-color;
      top: $info-clock-top;
      right: $info-clock-right;
      position: absolute;
    }
  }
}
